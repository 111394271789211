<template>
    <div>
    </div>
</template>

<script>

    export default {
        name: 'App',
        data(){
            return {
                curNavName: '首页',
                navlist:[
                    {
                        name:'首页',
                        id: 'index'
                    },
                    {
                        name:'产品',
                        id: 'product'
                    },
                    {
                        name:'解决方案',
                        id: 'solution'
                    },
                    {
                        name:'客户案例',
                        id: 'partners'
                    },
                    {
                        name:'关于我们',
                        id: 'aboutUs'
                    }
                ]
            }
        },
        mounted() {
        },
    }
</script>

<style>
    * {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    html, body {
        position: relative;
        width: 100%;
        height: 100%;
    }

</style>
